"use client"
import { cn } from "@/lib/utils"
import { cva } from "class-variance-authority"
import PropTypes from "prop-types"

const button = cva("button", {
  variants: {
    intent: {
      primary: ["bg-cta-1", "fill-cta-2", "text-cta-2", "border-cta-1"],
      secondary: ["bg-cta-2", "fill-cta-1", "text-cta-1", "border-cta-1"],
      tertiary: ["bg-transparent", "fill-cta-1", "text-cta-1", "border-cta-1"],
      primary_disable: [
        "bg-cta-4",
        "fill-text-3",
        "text-text-3",
        "border-transparent",
      ],
      secondary_disable: [
        "bg-transparent",
        "text-text-3",
        "fill-text-3",
        "border-transparent",
      ],
    },
    size: {
      medium: ["text-[16px] font-bold font-font-family-7"],
    },
  },
  compoundVariants: [
    {
      intent: [
        "primary",
        "secondary",
        "tertiary",
        "primary_disable",
        "secondary_disable",
      ],
      size: "medium",
      class:
        "ease-out duration-300 capitalize block w-[100%] p-[12px] rounded-[10px] box-border relative overflow-hidden",
    },
  ],
  defaultVariants: {
    intent: "primary",
    size: "medium",
  },
})

export default function ButtonAction({
  intent,
  size,
  className,
  children,
  innerClass,
  noTextFill = false,
  isMax = false,
  ...props
}) {
  const switchBG = () => {
    switch (intent) {
      case "primary":
        return "var(--cta_1)"
      case "secondary":
        return "var(--cta_2)"
      case "primary_disable":
        return "var(--cta_4)"
      default:
        return "transparent"
    }
  }
  const switchBorder = () => {
    switch (intent) {
      case "primary":
        return "var(--cta_1)"
      case "tertiary":
        return "transparent"
      case "secondary_disable":
        return "transparent"
      case "secondary":
        return "var(--cta_1)"
      default:
        return "var(--text_3)"
    }
  }
  const switchText = () => {
    switch (intent) {
      case "primary":
        return "var(--cta_2)"
      case "secondary":
        return "var(--cta_1)"
      case "tertiary":
        return "var(--cta_1)"
      default:
        return "var(--text_3)"
    }
  }
  const style = { background: switchBorder() }
  return (
    <button
      className={button({ intent, size, className })}
      {...props}
      style={style}
    >
      <div
        className={cn(
          "absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-[calc(100%_-_1.9px)] h-[calc(100%_-_1.9px)] z-[0] rounded-[9px]",
          innerClass
        )}
        style={{ background: switchBG() }}
      ></div>
      <span
        className={cn(
          "!bg-clip-text block relative z-[1]",
          isMax ? "!w-max !min-w-max" : ""
        )}
        style={
          noTextFill
            ? {}
            : { background: switchText(), WebkitTextFillColor: "transparent" }
        }
      >
        {children}
      </span>
    </button>
  )
}

ButtonAction.propTypes = {
  className: PropTypes.string,
  intent: PropTypes.string,
  size: PropTypes.string,
  props: PropTypes.object,
}
