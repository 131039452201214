import { clsx } from "clsx"
import { twMerge } from "tailwind-merge"

export function cn(...inputs) {
  return twMerge(clsx(inputs))
}

export const wait = (ms = 1000) =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve()
    }, ms)
  })

export function getPathFromParams(page = []) {
  if (page.length === 0) return "/"

  return "/" + page.join("/")
}

export function getFormatFromParams(page = []) {
  const bridgingPage =
    page.length === 3 && page[0].split("-").includes("bridging")
  const bridgingFormat = page.length === 3 && page[0].split("-")

  const detailFormat =
    page[4] === "article" ||
    page[4] === "video" ||
    page[4] === "carousel" ||
    page[4] === "wordle" ||
    page[4] === "puzzle" ||
    page[4] === "media_submission" ||
    page[4] === "text_submission" ||
    page[4] === "media-submission" ||
    page[4] === "text-submission" ||
    page[4] === "quiz" ||
    page[4] === "ugc"
  const detailPage = page.length === 6 && detailFormat
  const formatDetail = page.length === 6 && page[4].split("-").join("_")

  if (bridgingPage) return bridgingFormat[2]
  else if (detailPage) return formatDetail
  else return ""
}

export function getBrandedFromParams(page = []) {
  const bridgingPage =
    page.length === 3 && page[0].split("-").includes("bridging")
  const brandedPage = page[1] === "br"
  const unbrandedPage = page[1] === "ub"

  if (bridgingPage && brandedPage) return "1"
  else if (bridgingPage && unbrandedPage) return "0"
  else return ""
}

export function getFileFormatFromURL(url) {
  // Get the last part of the URL after the last dot
  const parts = url.split(".")
  if (parts.length > 1) {
    const fileFormat = parts.pop()
    return fileFormat
  } else {
    // No file extension found
    return null
  }
}

export function getCssString(cssConfig) {
  let cssString = ""

  // const variables = cssConfig.variables;

  // cssConfig.fonts.font_family?.forEach((font) => {
  //   cssString += font.url + "\n";
  // });

  cssConfig?.fonts.custom_font?.forEach((font) => {
    const format = getFontFormatFromUrl(font.font_url)

    cssString += "@font-face {\n"
    // eslint-disable-next-line no-useless-escape
    cssString += `  src: url("${font.font_url}") format("${format}");\n`
    cssString += `  font-family: "${font.font_face}";\n`
    cssString += `  font-weight: "${font.font_weight}";\n`
    cssString += `  font-style: "${font.font_style}";\n`
    cssString += "}\n"
  })

  cssString += ":root {\n"

  cssConfig?.colors.forEach((color) => {
    cssString += `  --${color.name}: ${color.value};\n`
  })

  cssConfig?.fonts?.font_family?.forEach((font) => {
    cssString += `  --${font.key}: ${font.value};\n`
  })

  cssString += "}\n"

  return cssString
}

function getFontFormatFromUrl(url) {
  // Map common font file extensions to their corresponding formats
  const formatMap = {
    woff: "woff",
    woff2: "woff2",
    ttf: "truetype",
    otf: "opentype",
    eot: "embedded-opentype",
    svg: "svg",
  }

  // Extract the file extension from the URL
  const urlParts = url.split(".")
  const extension = urlParts[urlParts.length - 1].toLowerCase()

  // Check if the extension exists in the format map
  if (formatMap[extension]) {
    return formatMap[extension]
  } else {
    // If the extension is not in the map, you can return it as is, but it may not work in all browsers.
    return extension
  }
}

export function getRandomFloat() {
  const crypto = window.crypto || window.msCrypto
  const array = new Uint32Array(1)
  const random = crypto.getRandomValues(array)[0]
  return random / Math.pow(2, 32)
}

export async function getUrlFromFile(file) {
  const reader = new FileReader()
  reader.readAsDataURL(file)
  return new Promise((resolve) => {
    reader.onloadend = (ev) => {
      resolve(ev.target.result)
    }
  })
}

class Semver {
  constructor(major, minner, patch) {
    this.major = major
    this.minner = minner
    this.patch = patch
  }

  toString() {
    return `${this.major}_${this.minner}_${this.patch}`
  }
}

export function isIOS() {
  const ua = navigator.userAgent
  return (
    ua.indexOf("iPhone") > 0 ||
    ua.indexOf("iPad") > 0 ||
    ua.indexOf("iPhone") > 0
  )
}

export function getIOSSemever() {
  if (isIOS()) {
    const extract = navigator.userAgent.match(/OS (\d+)_(\d+)_?(\d+)?/)
    return new Semver(
      parseInt(extract[1] || 0, 10),
      parseInt(extract[2] || 0, 10),
      parseInt(extract[3] || 0, 10)
    )
  } else {
    return null // or [0,0,0]
  }
}
