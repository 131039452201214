import { ClientApiList } from "@/lib/api/client-service"
import { useQuery } from "@tanstack/react-query"
import { useRouter } from "next/navigation"
import { useEffect } from "react"
// import { useGetLoginStatus } from './login-status';

export const useIsLogin = ({ shouldLogin, redirect, redirectTo } = {}) => {
  const { data } = useQuery({
    queryKey: ["isLogin"],
    queryFn: async () => {
      const { data: dataStatus } = await ClientApiList.getStatusLogin()
      return dataStatus
    },
  })

  const isLoading = data?.data === undefined

  const isLogin = isLoading ? null : data?.data?.data?.login_status

  const router = useRouter()

  useEffect(() => {
    if (!isLoading && isLogin !== null && !isLogin && redirectTo) {
      router.replace({
        pathname: redirectTo,
        query: {
          to: "login",
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogin, redirectTo, isLoading])

  useEffect(() => {
    if (shouldLogin && !isLogin) {
      router.push({
        query: {
          redirect,
          to: "login",
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogin, shouldLogin])

  return {
    isLoading,
    isLogin,
  }
}
